header {
    height: 130vh;
    padding-top: 5rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position:relative;
}

/*=========== CTA ============*/

.cta {
    margin-top: 2rem ;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/*============== Socials ============*/

.header__socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.6rem;
    position: absolute;
    left: 0;
    bottom: 2rem;
}

.header__socials::after{
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/*============== ME ============*/

.me {
    /* background: linear-gradient(var(--color-primary), transparent); */
    width: 18rem;
    height: 24rem;
    position: absolute;
    /* justify-content: center; */
    left: calc(50% - 9rem);
    margin-top: 5rem;
    border-radius: 1rem 1rem 1rem 1rem;
    overflow: hidden;
    padding: 0rem 0rem 0rem 0rem;
}

/*============== Scroll Down ============*/

.scroll__down {

    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 1rem;
}

/*============ MEDIA QUERIES (Medium Devices) ==========*/
@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
}

/*============ MEDIA QUERIES (Small Devices) ==========*/
@media screen and (max-width: 600px) {
    
    header {
        height: 100vh;
    }
    .header__socials, .scroll__down{
        display:grid;
    }
    .me {
        width: 17rem;
        margin-left: 2.5rem;
        margin-top: 5rem;

    }
}