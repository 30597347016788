.container.testimonials_container{
    width: 60%;
}

.client_avatar{
    width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.2rem solid var(--color-primary);
}

.testimonials {
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.client_review{
    color: var(--color-light);
    font-weight: 300;
    font-size: medium;
    display: block;
    width: 90%;
    margin: 0.8rem auto 0;
    text-align: justify;
}

.client_position{
    color: var(--color-primary);
}


/*============ MEDIA QUERIES (Medium Devices) ==========*/
@media screen and (max-width: 1024px) {
    .testimonials_container{
        width: 60%;
    }
    
}

/*============ MEDIA QUERIES (Small Devices) ==========*/
@media screen and (max-width: 600px) {

        .testimonials_container{
            width: var(--container-width-ms);

        }
        .client_review{
            width: var(--container-width-ms);
        }

}
